<template>
    <div class="navmb">
        <div class="flex flexa">
        <el-icon color="#FF6A00" size="20" class="astyle bgshadow" @click="$router.back()"><Back /></el-icon> &nbsp; &nbsp;
        <span class="">修改密码</span>
        </div>
    </div>
    <p class="h20"></p>
    <div class="regmid bgf ">
        <div class="midhead borbot font14 col9">
            <p class="col24">01.身份验证</p>
            <div class="pad14">
                <img v-if="step>=2"  :src="utils.loadImg('line-a.png')" alt="">
                <img v-else :src="utils.loadImg('line.png')" alt="">
            </div>
            <p :class="step>=2?'col24':''">02.设置新密码</p>
            <div class="pad14">
                <img v-if="step>=3"  :src="utils.loadImg('line-a.png')" alt="">
                <img v-else :src="utils.loadImg('line.png')" alt="">
            </div>
            <p :class="step>=3?'col24':''">03.完成</p>
        </div>
        <div v-if="step==1" class="wmid">
            <p class="h40"></p>
            <ul class="tabs">
                <li @click="tab=1;info.authCode='';" :class="tab==1?'act':''">通过手机号验证<p></p> </li>
                <li @click="tab=2;info.authCode='';" :class="tab==2?'act':''">通过邮箱号验证<p></p> </li>
            </ul>
            <div class="step2">
                <ul v-if="tab==1">
                    <li>
                        <label for="">已绑定手机号：</label>
                        <p>{{ utils.hidephone(query.mobile) }}</p>
                    </li>
                    <li>
                        <label for="">手机验证码：</label>
                        <el-input class="w227" v-model="info.authCode" placeholder="输入验证码" size="large"/>
                        <button @click="handleGetSms(query.mobile)" class="btn1">{{yzmSend ? '重新发送('+ time+'s)' : '获取手机验证码'}}</button>
                    </li>
                    <li>
                        <label for=""></label>
                        <el-button @click="next1" type="primary" class="w125" size="large" >
                            下一步
                        </el-button>
                    </li>
                </ul>

                <ul v-if="tab==2">
                    <li>
                        <label for="">已绑定邮箱：</label>
                        <p>{{ utils.hidephone(query.email) }}</p>
                    </li>
                    <li>
                        <label for="">邮箱验证码：</label>
                        <el-input class="w227" v-model="info.authCode" placeholder="输入验证码" size="large"/>
                        <button @click="handleGetSms(query.email)" class="btn1">{{yzmSend ? '重新发送('+ time+'s)' : '获取邮箱验证码'}}</button>
                    </li>
                    <li>
                        <label for=""></label>
                        <el-button @click="next1" type="primary" class="w125" size="large" >
                            下一步
                        </el-button>
                    </li>
                </ul>
            </div>
        </div>

        <div v-if="step==2" class="step3">
            <p class="colb6 font12 lh24 padb24 tc">支持字母、数字等字母密码进行设置</p>

            <el-input placeholder="请输入新密码" v-model="param.password" size="large" type="password" />
            <p class="h24"></p>
            <el-input placeholder="确认密码" v-model="password" size="large" type="password"/>
            <p class="h45"></p>
            <el-button @click="downten()" type="primary" class="w100" size="large" >
                提交
            </el-button>
            <p class="h30"></p>
        </div>

        <div v-if="step==3" class="step4">
            <div class="flex flexa flexc padb40 padt20">
                <img :src="utils.loadImg('success.png')" alt="" width="43" height="43" srcset="">
               <p class="col24 font22 padl20 ">恭喜您，密码修改成功！</p>
            </div>
            <p class="h20"></p>
            <el-button @click="$router.push('/account')" type="primary" class="w172" size="large" >
                返回设置中心
            </el-button>
        </div>
    </div>
</template>
<script>
import { sendVerificationCode , sendResetPasswordEmail, changeMobile, editAccountPassword } from '@/utils/api1.js'
import { Back } from '@element-plus/icons-vue'
export default {
    components: {
        Back
    },
    data() {
        return{
            info:{
                mobile: '',
                authCode: '',
            },
            param:{
                password:'',
            },
            password:'',
            query:this.$route.query,
            step: 1,
            tab:1,
            authCode: '',            
            time:60,
            ten:10,
            yzmSend: false,
            timmer: null,
            deadline: Date.now() + 1000 * 10 ,
        }
    },
    mounted(){
        
    },
    methods: {
        next1(){
            if(this.tab==1){
                this.info.mobile = this.query.mobile;
            }else{
                this.info.mobile = this.query.email;
            }
            changeMobile(this.info).then(res=>{
                console.log(res);
                if(res){
                    this.step = 2;
                    this.time = 60;
                    this.yzmSend = false;
                }
            })
        },

        handleGetSms(phone) {
            let that = this;
            if (that.yzmSend) return;

            if(that.tab==1){
                sendVerificationCode({mobile:phone}).then(res=>{
                    console.log(res);
                    if(res){
                        that.$message.success("验证码发送成功！");
                    }
                });
            }else{
                sendResetPasswordEmail({email:phone}).then(res=>{
                    console.log(res);
                    if(res){
                        that.$message.success(res.message);
                    }
                });
            }
            
            if(!that.yzmSend){
                that.yzmSend = true;
                that.timmer = setInterval(function () {
                    if (that.time == 1) {
                        that.time = 60;
                        that.yzmSend = false;
                        clearInterval(that.timmer);
                        return;
                    }
                    that.time--;
                }, 1000);
            }
        },

        onFinish(){
            console.log('完成');
            this.$router.push('/login');
        },

        downten(){
            var that = this;
            if(that.param.password != that.password ){
                that.$message.warning('输入密码不同！');
                return
            }

            that.param.mobile = that.info.mobile;
            console.log(that.param)
            editAccountPassword(that.param).then(res=>{
                console.log(res);
                if(res){
                    let mytimmer;
                    mytimmer = setInterval(function () {
                        if (that.ten == 1) {
                            clearInterval(mytimmer);
                            that.$router.push('/login');
                            that.ten = 10;
                            return;
                        }
                        that.ten--;
                    }, 1000);
                    that.step=3;
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.w227{ width: 227px;}
.w172{ width: 172px;}
.w125{ width: 125px;}
.padt3{ padding-top: 3px;}
.mw{
    min-width: 1090px;
}
.img1{
    width: 96px;
    height: 40px;
    border-radius: 2px;
    border: 1px solid #E6E8EB;
    margin-left: 10px;
}
.step4{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
}
.step2{
    padding-top: 60px;
    display: flex;
    justify-content: center;
    >ul{
        color: #3F4651;
        font-size: 12px;
        >li{
            padding-bottom: 36px;
            display: flex;
            align-items: center;
            >label{
                width: 86px;
                min-width: 86px;

            }
        }
    }
}
.step3{
    padding-top: 45px;
    width: 320px;
    margin:0 auto;
}


.regmid{
    box-sizing: border-box;
    padding: 0 64px 57px;
    min-height: calc(100vh - 140px);
}
.wmid{
    width: 460px;
    margin: 0 auto;
}
.midhead{
    line-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.btn1{
    margin-left: 14px;
    border-radius: 2px;
    color: #71767E;
    font-size: 12px;
    width: 155px;
    min-width: 155px;
    height: 40px;
}
.tabs{
    display: flex;
    // justify-content: space-around;
    font-size: 18px;
    color: #242424;
    li>p{
        width: 80px;
        height: 4px;
        background: #fff;
        margin: 16px auto;
    }
    li{
        cursor: pointer;
        margin-right: 40px;
    }
    .act{
        color: #FF6A00;
        >p{
            background: #FF6A00;
        }
    }
}
</style>